import utility from '@/common/utility'
import auth from '@/common/auth'

export default class G13Model {
  constructor (props) {
    if (props) {
      this.resourceId = props.g13Guid
      this.g13Guid = props.g13Guid
      this.g28Guid = props.g28Guid
      this.planStatExaminationGuid = props.planStatExaminationGuid
      this.materialListGuid = props.materialListGuid
      this.currentMonthNum = props.currentMonthNum
      this.currentMonthPercent = props.currentMonthPercent
      this.totalMonthNum = props.totalMonthNum
      this.totalMonthPercent = props.totalMonthPercent
      this.totalNum = props.totalNum
      this.totalPercent = props.totalPercent
      this.sortId = props.sortId
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
      this.tenderGuid = props.tenderGuid
      this.year = props.year
      this.month = props.month
      this.monthSelfTestNum = props.monthSelfTestNum
      this.monthSelfTestGoodNum = props.monthSelfTestGoodNum
      this.monthSelfTestGoodPercent = props.monthSelfTestGoodPercent
      this.monthTestNum = props.monthTestNum
      this.monthTestGoodNum = props.monthTestGoodNum
      this.monthTestGoodPercent = props.monthTestGoodPercent
      this.totalSelfTestNum = props.totalSelfTestNum
      this.totalSelfTestGoodNum = props.totalSelfTestGoodNum
      this.totalSelfTestGoodPercent = props.totalSelfTestGoodPercent
      this.totalTestNum = props.totalTestNum
      this.totalTestGoodNum = props.totalTestGoodNum
      this.totalTestGoodPercent = props.totalTestGoodPercent
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.g13Guid) this.g13Guid = ''
    if (!this.g28Guid) this.g28Guid = ''
    if (!this.planStatExaminationGuid) this.planStatExaminationGuid = ''
    if (!this.materialListGuid) this.materialListGuid = ''
    if (!this.currentMonthNum) this.currentMonthNum = 0
    if (!this.currentMonthPercent) this.currentMonthPercent = 0
    if (!this.totalMonthNum) this.totalMonthNum = 0
    if (!this.totalMonthPercent) this.totalMonthPercent = 0
    if (!this.totalNum) this.totalNum = 0
    if (!this.totalPercent) this.totalPercent = 0
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
    if (!this.tenderGuid) this.tenderGuid = ''
    if (!this.year) this.year = 0
    if (!this.month) this.month = 0
    if (!this.monthSelfTestNum) this.monthSelfTestNum = 0
    if (!this.monthSelfTestGoodNum) this.monthSelfTestGoodNum = 0
    if (!this.monthSelfTestGoodPercent) this.monthSelfTestGoodPercent = 0
    if (!this.monthTestNum) this.monthTestNum = 0
    if (!this.monthTestGoodNum) this.monthTestGoodNum = 0
    if (!this.monthTestGoodPercent) this.monthTestGoodPercent = 0
    if (!this.totalSelfTestNum) this.totalSelfTestNum = 0
    if (!this.totalSelfTestGoodNum) this.totalSelfTestGoodNum = 0
    if (!this.totalSelfTestGoodPercent) this.totalSelfTestGoodPercent = 0
    if (!this.totalTestNum) this.totalTestNum = 0
    if (!this.totalTestGoodNum) this.totalTestGoodNum = 0
    if (!this.totalTestGoodPercent) this.totalTestGoodPercent = 0
  }

  generatePrimaryKey () {
    this.g13Guid = utility.getUuid()
    this.resourceId = this.g13Guid
  }
}
