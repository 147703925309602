const G13SpreadInfo = {
  sheetName: 'G13',
  headerRowCount: 2,
  headerColumnCount: 13,
  headerColumns: [
    {
      index: 0,
      name: '序号',
      width: '110'
    },
    {
      index: 1,
      name: '材料名称及规格',
      width: '100',
      children: [
        {
          index: 1,
          name: '工程部位',
          width: '150'
        },
        {
          index: 2,
          name: '材料名称',
          width: '200'
        }
      ]
    },
    {
      index: 3,
      name: '单位',
      width: '60'
    },
    {
      index: 4,
      name: '合同总用量',
      width: '90'
    },
    {
      index: 5,
      name: '本年度计划材料用量',
      width: '140'
    },
    {
      index: 6,
      name: '本月计划材料用量',
      width: '130'
    },
    {
      index: 7,
      name: '本月完成',
      children: [
        {
          index: 7,
          name: '材料用量',
          width: '90'
        },
        {
          index: 8,
          name: '占月计划%',
          width: '90'
        }
      ]
    },
    {
      index: 9,
      name: '本年完成',
      children: [
        {
          index: 9,
          name: '材料用量',
          width: '90'
        },
        {
          index: 10,
          name: '占年计划%',
          width: '90'
        }
      ]
    },
    {
      index: 11,
      name: '全部工程累计完成',
      children: [
        {
          index: 11,
          name: '材料用量',
          width: '90'
        },
        {
          index: 12,
          name: '占合同%',
          width: '90'
        }
      ]
    }
  ]
}

export default G13SpreadInfo
